@use "../global/" as *;

.projects-wrapper {
  background-color: $off-white;
  overflow-x: hidden;

  width: 100vw;

  .projects-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5rem;

    padding: 10rem 5rem;

    height: 100%;
    width: 100%;

    @media (max-width: 768px) {
      gap: 2.5rem;
      padding: 2.5rem;
    }

    .projects-title {
      @include h3-text;
      text-transform: uppercase;
    }

    .tech-stack {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      height: 10%;

      .tech-stack-title {
        @include h3-text;
        color: $blue;
      }

      .tech-stack-inner-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 3.5rem;

        width: auto;

        padding: 1.5rem 3rem;

        .stack-item {
          padding: 1rem;

          .svg-logo {
            cursor: pointer;

            width: 5rem;
          }
        }
      }
    }
    .projects-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 7.5rem;

      height: 100%;

      padding: 2.5rem;

      @media (max-width: 375px) {
        padding: 1rem;
        gap: 5rem;
      }
    }
  }
}
