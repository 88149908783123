@use "./colors" as *;

// font family
$font: "Fira Sans", sans-serif;

// font sizes
$h1-size: 6.4rem;
$h2-size: 4.8rem;
$h3-size: 3.2rem;
$h4-size: 2.4rem;
$text-normal: 2rem;
$text-small: 1.6rem;
$text-x-small: 1.2rem;

// font weights
$very-light: 300;
$light: 400;
$regular: 500;
$heavy: 600;
$bold: 800;
$very-bold: 900;

@mixin h4-text {
  font-size: $h4-size;
  font-weight: $bold;
  letter-spacing: 0.15rem;
  color: $blue;
}

@mixin h3-text {
  font-size: $h3-size;
  font-weight: $bold;
  letter-spacing: 0.2rem;
  color: $blue;
}

@mixin h2-text {
  font-size: $h2-size;
  font-weight: $bold;
  letter-spacing: 0.25rem;
  color: $blue;
}

@mixin regular-text {
  font-size: $text-normal;
  font-weight: $regular;
  letter-spacing: 0.15rem;
  line-height: 2.5rem;
  color: $blue;
}

@mixin small-text {
  font-size: $text-small;
  font-weight: $regular;
  letter-spacing: 0.1rem;
  line-height: 3rem;
  color: $blue;
}

@mixin xsmall-text {
  font-size: $text-x-small;
  font-weight: $light;
  letter-spacing: 0.1rem;
  line-height: 3rem;
  color: $blue;
}
