// colors
$white: #fbfbfb;
$off-white: #f7f7f7;
$black: #111111;
$blue: #003262;
$vo-light-blue: rgb(179, 194, 208, 0.2);
$v-light-blue: #b3c2d0;
$o-light-blue: rgba(77, 112, 145, 0.2);
$light-blue: #4d7091;
$dark-blue: #002345;
$v-dark-blue: #00050a;
