@use "../global/" as *;

.hero-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $off-white;

  height: 100vh;
  width: 100vw;

  @media (max-width: /* 768 */1024px) {
    height: fit-content;
  }
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 22.5rem;
  padding: 0 2.5rem;

  @media (min-width: 1024px) {
    @media (max-height: 1000px) {
      margin-bottom: 7.5rem;
    }
    @media (max-height: 750px) {
      margin-top: 15rem;
    }
  }

  @media (max-width: 1024px) {
    @media (max-height: 1144px) {
      margin: 0 15rem;
      height: fit-content;
    }
  }

  @media (max-width: /* 475 */1024px) {
    margin-bottom: 7.5rem;
  }

  .hero-contents {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    gap: 5rem;

    width: 100%;
    height: 100%;

    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 5rem;
      margin-top: 15rem;
    }
    @media (max-width: 768px) {
      margin-top: 10rem;
    }

    .hero-header {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      width: 33%;

      animation: dropin 750ms ease-out;
      animation-play-state: paused;

      @media (max-width: 1024px) {
        text-align: center;
        width: 100%;
      }

      .hero-statement {
        @include h2-text;
        width: 100%;

        @media (max-width: 1024px) {
          text-align: center;
        }
      }

      .personal-links {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 2.5rem;

        margin: 1rem 0 5rem 0;

        @media (max-width: 1024px) {
          justify-content: center;
          width: 100%;
        }
        @media (max-width: 768px) {
          margin: 1rem 0 2.5rem 0;
        }

        .personal-link {
          fill: $blue;
          cursor: pointer;

          width: 3.5rem;

          transition: all 100ms ease-in-out;

          &:hover {
            fill: $light-blue;
            transition: all 100ms ease-in-out;
          }
        }
      }
    }

    .hero-blurb {
      @include regular-text;

      margin-bottom: 7.5rem;

      span {
        font-weight: $very-bold;
      }

      @media (max-width: 768px) {
        margin-bottom: 5rem;
      }
    }

    .reach-out {
      @media (max-width: 1024px) {
        align-self: center;
      }
      @media (max-width: 768px) {
        @include regular-text;
        font-weight: $bold;
      }
    }

    .hero-img-wrapper {
      animation: growin 750ms ease-out;
      animation-play-state: paused;

      .hero-img-container {
        border: 5px solid $blue;
        overflow: hidden;

        height: 45rem;
        width: 45rem;

        animation: border-move 15s ease-in-out infinite;

        @media (max-width: 475px) {
          width: 35rem;
          height: 35rem;
        }
        @media (max-width: 375px) {
          width: 30rem;
          height: 30rem;
        }

        .hero-img {
          width: 100%;
        }
      }
    }
  }
}

@keyframes border-move {
  0% {
    border-radius: 100% 25% 50% 75%;
  }
  25% {
    border-radius: 75% 100% 25% 50%;
  }
  50% {
    border-radius: 50% 75% 100% 25%;
  }
  75% {
    border-radius: 25% 50% 75% 100%;
  }
  100% {
    border-radius: 100% 25% 50% 75%;
  }
}

@keyframes dropin {
  0% {
    transform: translateY(-25%);
    opacity: 0;
  }
  75% {
    transform: translateY(2.5%);
  }
  90% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes growin {
  0% {
    transform: scale(0%);
    opacity: 0;
  }
  75% {
    transform: scale(105%);
  }
  90% {
    transform: scale(97.5%);
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}
