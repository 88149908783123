@use "../global/" as *;

.about-wrapper {
  background-color: $white;
  overflow-x: hidden;

  height: 50vh;
  width: 100vw;

  @media (max-width: 1280px) {
    height: fit-content;
  }

  @media (max-width: /* 768 */1024px) {
    height: fit-content !important;
  }

  @media (max-height: 800px) {
    padding: 1rem;

    height: auto;
  }

  .about-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10rem;

    height: 100%;

    padding: 0 10rem 0 15rem;

    @media (max-width: 1600px) {
      gap: 5rem;
      width: 80%;
      padding: 2.5rem;
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      gap: 2.5rem;
      width: 90%;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 2.5rem;
      width: 100%;
    }

    animation: about-slide-in 750ms ease-out;
    animation-play-state: paused;

    .about-img-container {
      border: 4px solid $blue;
      overflow: hidden;

      width: 40%;

      animation: about-border-move 15s ease-in-out infinite;

      @media (max-width: 1600px) {
        width: 50%;
      }
      // @media (max-height: 1380px) {
      // }
      @media (max-width: 1280px) {
        width: 100%;
      }
      @media (max-width: 1024px) {
        width: 40%;
      }
      @media (max-width: 768px) {
        width: 50%;
      }
    }

    .about-img {
      display: block;

      width: 100%;
    }

    .about-info {
      @media (max-width: /* 768 */1024px) {
        width: 100%;
      }

      .about-text {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 2.5rem;

        @media (max-width: 1024px) {
          width: 100%;
        }

        .about-title {
          @include h3-text;
          text-transform: uppercase;
        }

        .about-blurb {
          @include regular-text;
          font-weight: $light;

          @media (max-width: 475px) {
            @include small-text;
            font-weight: $regular;
            letter-spacing: 0.175rem;
            line-height: 2.5rem;
          }
        }

        @media (max-width: 1024px) {
          align-items: center;
          justify-content: center;
          gap: 2.5rem;

          text-align: center;

          width: 100%;
        }
      }

      .tech-stack {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5rem;

        margin: 5rem 0 0 0;

        @media (max-width: /* 768 */1024px) {
          flex-direction: column;
          gap: 2.5rem;
          margin: 2.5rem 0 2.5rem 0;
        }

        .tech-stack-title {
          @include regular-text;

          border-right: 1px solid $blue;

          padding-right: 1.5rem;

          @media (max-width: /* 768 */1024px) {
            align-self: center;
            border-right: none;
            border-bottom: 1px solid $blue;
            padding-right: 0;
          }
        }

        .tech-stack-inner-container {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 2.5rem;
          flex-wrap: wrap;

          @media (max-width: /* 768 */1024px) {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}

@keyframes about-border-move {
  0% {
    border-radius: 70% 60% 70% 50% / 60% 50% 70% 80%;
  }
  25% {
    border-radius: 60% 50% 70% 80% / 50% 70% 60% 70%;
  }
  50% {
    border-radius: 50% 80% 60% 70% / 50% 70% 60% 70%;
  }
  75% {
    border-radius: 60% 80% 50% 70% / 60% 80% 50% 70%;
  }
  100% {
    border-radius: 70% 60% 70% 50% / 60% 50% 70% 80%;
  }
}

@keyframes about-slide-in {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  75% {
    transform: translateX(-2%);
  }
  90% {
    transform: translateX(1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}
