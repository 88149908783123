@use "../global/" as *;

.project-wrapper {
  .project {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2.5rem;

    outline: 1px solid $o-light-blue;
    outline-offset: 1rem;

    height: 100%;
    width: 80%;

    margin: 0 auto;

    transition: all 150ms ease-in-out;

    animation: proj-slide-left 750ms ease-out;
    animation-play-state: paused;

    @media (max-width: 375px) {
      width: 70%;
    }

    &:hover {
      outline: 1px solid $light-blue;
      transition: all 150ms ease-in-out;
    }

    &.reverse {
      flex-direction: row-reverse;

      animation: proj-slide-right 750ms ease-out;
      animation-play-state: paused;

      @media (max-width: 1024px) {
        flex-direction: column-reverse;
      }
    }

    @media (max-width: 1280px) {
      width: 100%;
    }
    @media (max-width: 1024px) {
      flex-direction: column-reverse;
      gap: 1rem;
    }
    @media (max-width: 475px) {
    }

    .project-img-container {
      overflow: hidden;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);

      width: 100%;

      .project-img {
        display: block;
        width: 100%;
      }
    }

    .project-info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      gap: 1rem;

      height: 100%;
      width: 100%;

      padding: 2.5rem;

      @media (max-width: 1024px) {
        padding: 1rem;
      }

      .project-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        width: 100%;

        padding: 2.5rem 0;

        @media (max-width: 768px) {
          padding: 1rem 0;
        }

        .project-title {
          @include h4-text;
          text-transform: uppercase;
        }
        .project-date {
          @include small-text;
          letter-spacing: 0.05rem;
        }
      }
      .project-about {
        flex-grow: 1;

        @include small-text;
        line-height: 2rem;
      }
      .project-details {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5rem;

        width: 100%;

        padding-top: 3.5rem;

        @media (max-width: 768px) {
          gap: 2.5rem;
        }

        .project-skills {
          display: flex;
          align-items: center;
          align-self: flex-start;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 1rem;

          // padding: 2.5rem 0;

          @media (max-width: 475px) {
            padding: 1rem 0;
          }

          .project-skill {
            background-color: $v-light-blue;
            @include xsmall-text;
            line-height: 2rem;

            padding: 0.5rem 1rem;

            @media (max-width: 475px) {
              padding: 0.25rem 0.5rem;
            }
          }
        }

        .project-links {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: flex-start;
          gap: 1.5rem;

          .project-link {
            fill: $blue;

            width: 2.5rem;
            height: 2.5rem;
          }

          .external {
            stroke: $blue;
            fill: transparent;
          }
        }
      }
    }
  }
}

.disabled {
  pointer-events: none;
  filter: contrast(20%);
}

@keyframes proj-slide-left {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  75% {
    transform: translateX(-2.5%);
  }
  90% {
    transform: translateX(1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes proj-slide-right {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  75% {
    transform: translateX(2.5%);
  }
  90% {
    transform: translateX(-1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}
