@use "../global/" as *;

.contact-wrapper {
  background-color: $white;

  width: 100vw;

  .contact-container {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100%;

    animation: contact-slide-up 750ms ease-out;
    animation-play-state: paused;

    .contact-contents {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      gap: 2.5rem;

      height: 100%;
      width: 70%;

      margin: 2.5rem 1.25rem;
      padding: 2.5rem;

      @media (max-width: 768px) {
        width: 100%;
      }
      @media (max-width: 475px) {
        padding: 0;
      }

      .form-title {
        @include h3-text;
        color: $dark-blue;
        text-transform: uppercase;

        @media (max-width: 768px) {
          @include h4-text;
        }
      }

      .contact-blurb {
        @include regular-text;
        text-align: center;

        width: 65%;

        margin-bottom: 1rem;

        @media (max-width: 768px) {
          width: 100%;
        }
        @media (max-width: 475px) {
          @include small-text;
        }
      }
    }
  }
}

@keyframes contact-slide-up {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  75% {
    transform: translateY(-2.5%);
  }
  90% {
    transform: translateY(1%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
