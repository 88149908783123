@use "../global/" as *;

.navbar-container {
  position: fixed;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: $white;
  box-shadow: 0 0 1rem rgb(0, 0, 0, 0.1);
  z-index: 100;

  width: 100vw;

  padding: 2.5rem 7.5rem;

  @media (max-width: 1024px) {
    padding: 1.5rem 3rem;
  }

  .navbar-link-section {
    #menu {
      display: none;

      background-color: $blue;
      border-radius: 50%;
      border: none;

      width: 3.5rem;
      height: 3.5rem;

      animation: menu-pulse 1s linear infinite;

      &:hover {
        transform: scale(1.1);
        transition: all 50ms;
      }

      &:active {
        transform: scale(0.9);
        transition: all 10ms;
      }

      @media (max-width: 768px) {
        display: block;
      }
    }

    .navbar-links {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3.5rem;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .navbar-link {
    @include h4-text;

    transition: all 100ms ease-in-out;

    &:hover {
      color: $light-blue;
      transition: all 100ms ease-in-out;
    }

    @media (max-width: 768px) {
      @include regular-text;
      font-weight: $bold;
    }
  }

  .resume {
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: -100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  background-color: $off-white;
  z-index: 999;
  transition: 250ms ease-in-out;

  height: 100vh;
  width: 100vw;

  // changed from 768px to account for ipad mini
  @media (min-width: 769px) {
    display: none;
  }

  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    .nav-close {
      justify-self: flex-end;
      align-self: flex-end;

      background-color: $off-white;
      border: none;
      cursor: pointer;
      transition: 150ms ease-in-out;

      width: 5rem;
      height: 5rem;

      margin: 1rem;

      &:hover {
        transform: rotate(90deg);
      }

      .nav-close-icon {
        color: $blue;
      }
    }

    .dev {
      margin: 1rem 2.5rem;
    }
  }

  .navbar-link-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 3.5rem;
    flex-grow: 1;

    .navbar-links {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1.5rem;

      border-bottom: 1px solid $blue;

      padding-bottom: 2.5rem;
    }
  }
  .navbar-link {
    @include h4-text;

    transition: all 100ms ease-in-out;

    &:hover {
      color: $light-blue;
      transition: all 100ms ease-in-out;
    }

    @media (max-width: 768px) {
      @include regular-text;
      font-weight: $bold;
    }
  }
}

@keyframes menu-pulse {
  0% {
    box-shadow: 0 4px 10px rgba(77, 112, 145, 0.1),
      0 0 0 0 rgba(77, 112, 145, 0.1), 0 0 0 5px rgba(77, 112, 145, 0.1),
      0 0 0 10px rgba(77, 112, 145, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba(77, 112, 145, 0.1),
      0 0 0 5px rgba(77, 112, 145, 0.1), 0 0 0 10px rgba(77, 112, 145, 0.1),
      0 0 0 20px rgba(77, 112, 145, 0);
  }
}
