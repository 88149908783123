@use "../global/" as *;

.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $blue;

  padding: 5rem;

  width: 100vw;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  .footer-contents {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 10rem;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: 1rem;

      padding: 1rem;
    }

    .footer-statement {
      @include regular-text;
      font-weight: $light;
      color: $off-white;

      @media (max-width: 768px) {
        @include small-text;
        color: $off-white;
      }
    }

    .footer-links {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 10rem;

      .footer-link-icon {
        fill: $off-white;
        cursor: pointer;

        transition: all 150ms ease-in-out;

        &:hover {
          fill: $light-blue;

          transition: all 150ms ease-in-out;
        }
      }
    }
  }
}
