* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-family: "Fira Sans", sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.container {
  width: 1600px;
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem;
}
@media (max-width: 1600px) {
  .container {
    max-width: 1280px;
  }
}
@media (max-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}
@media (max-width: 1024px) {
  .container {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  .container {
    max-width: 100%;
  }
}

.btn {
  font-size: 2.4rem;
  font-weight: 800;
  letter-spacing: 0.15rem;
  color: #003262;
  box-shadow: -3.5px 3.5px 0px 3.5px #4d7091, 3.5px -3.5px 0px 3.5px #002345;
  background-color: #f7f7f7;
  color: #003262;
  padding: 0.75rem;
  animation: compound-shadow-reverse 350ms;
}
.btn:hover {
  animation: compound-shadow 350ms forwards;
}

@keyframes compound-shadow {
  from {
    box-shadow: -3.5px 3.5px 0px 3.5px #4d7091, 3.5px -3.5px 0px 3.5px #002345;
    background-color: #f7f7f7;
    color: #003262;
  }
  to {
    box-shadow: 0px 0px 0px 0px #4d7091, 0px 0px 0px 0px #002345;
    background-color: #003262;
    color: #fbfbfb;
  }
}
@keyframes compound-shadow-reverse {
  from {
    box-shadow: 0px 0px 0px 0px #4d7091, 0px 0px 0px 0px #002345;
    background-color: #003262;
    color: #fbfbfb;
  }
  to {
    box-shadow: -3.5px 3.5px 0px 3.5px #4d7091, 3.5px -3.5px 0px 3.5px #002345;
    background-color: #f7f7f7;
    color: #003262;
  }
}
.navbar-container {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fbfbfb;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 100vw;
  padding: 2.5rem 7.5rem;
}
@media (max-width: 1024px) {
  .navbar-container {
    padding: 1.5rem 3rem;
  }
}
.navbar-container .navbar-link-section #menu {
  display: none;
  background-color: #003262;
  border-radius: 50%;
  border: none;
  width: 3.5rem;
  height: 3.5rem;
  animation: menu-pulse 1s linear infinite;
}
.navbar-container .navbar-link-section #menu:hover {
  transform: scale(1.1);
  transition: all 50ms;
}
.navbar-container .navbar-link-section #menu:active {
  transform: scale(0.9);
  transition: all 10ms;
}
@media (max-width: 768px) {
  .navbar-container .navbar-link-section #menu {
    display: block;
  }
}
.navbar-container .navbar-link-section .navbar-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.5rem;
}
@media (max-width: 768px) {
  .navbar-container .navbar-link-section .navbar-links {
    display: none;
  }
}
.navbar-container .navbar-link {
  font-size: 2.4rem;
  font-weight: 800;
  letter-spacing: 0.15rem;
  color: #003262;
  transition: all 100ms ease-in-out;
}
.navbar-container .navbar-link:hover {
  color: #4d7091;
  transition: all 100ms ease-in-out;
}
@media (max-width: 768px) {
  .navbar-container .navbar-link {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.15rem;
    line-height: 2.5rem;
    color: #003262;
    font-weight: 800;
  }
}
@media (max-width: 768px) {
  .navbar-container .resume {
    display: none;
  }
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: -100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f7f7f7;
  z-index: 999;
  transition: 250ms ease-in-out;
  height: 100vh;
  width: 100vw;
}
@media (min-width: 769px) {
  .mobile-nav {
    display: none;
  }
}
.mobile-nav .nav-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.mobile-nav .nav-header .nav-close {
  justify-self: flex-end;
  align-self: flex-end;
  background-color: #f7f7f7;
  border: none;
  cursor: pointer;
  transition: 150ms ease-in-out;
  width: 5rem;
  height: 5rem;
  margin: 1rem;
}
.mobile-nav .nav-header .nav-close:hover {
  transform: rotate(90deg);
}
.mobile-nav .nav-header .nav-close .nav-close-icon {
  color: #003262;
}
.mobile-nav .nav-header .dev {
  margin: 1rem 2.5rem;
}
.mobile-nav .navbar-link-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3.5rem;
  flex-grow: 1;
}
.mobile-nav .navbar-link-section .navbar-links {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  border-bottom: 1px solid #003262;
  padding-bottom: 2.5rem;
}
.mobile-nav .navbar-link {
  font-size: 2.4rem;
  font-weight: 800;
  letter-spacing: 0.15rem;
  color: #003262;
  transition: all 100ms ease-in-out;
}
.mobile-nav .navbar-link:hover {
  color: #4d7091;
  transition: all 100ms ease-in-out;
}
@media (max-width: 768px) {
  .mobile-nav .navbar-link {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.15rem;
    line-height: 2.5rem;
    color: #003262;
    font-weight: 800;
  }
}

@keyframes menu-pulse {
  0% {
    box-shadow: 0 4px 10px rgba(77, 112, 145, 0.1), 0 0 0 0 rgba(77, 112, 145, 0.1), 0 0 0 5px rgba(77, 112, 145, 0.1), 0 0 0 10px rgba(77, 112, 145, 0.1);
  }
  100% {
    box-shadow: 0 4px 10px rgba(77, 112, 145, 0.1), 0 0 0 5px rgba(77, 112, 145, 0.1), 0 0 0 10px rgba(77, 112, 145, 0.1), 0 0 0 20px rgba(77, 112, 145, 0);
  }
}
.hero-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  height: 100vh;
  width: 100vw;
}
@media (max-width: 1024px) {
  .hero-wrapper {
    height: -moz-fit-content;
    height: fit-content;
  }
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 22.5rem;
  padding: 0 2.5rem;
}
@media (min-width: 1024px) and (max-height: 1000px) {
  .hero-container {
    margin-bottom: 7.5rem;
  }
}
@media (min-width: 1024px) and (max-height: 750px) {
  .hero-container {
    margin-top: 15rem;
  }
}
@media (max-width: 1024px) and (max-height: 1144px) {
  .hero-container {
    margin: 0 15rem;
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media (max-width: 1024px) {
  .hero-container {
    margin-bottom: 7.5rem;
  }
}
.hero-container .hero-contents {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  gap: 5rem;
  width: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .hero-container .hero-contents {
    flex-direction: column;
    gap: 5rem;
    margin-top: 15rem;
  }
}
@media (max-width: 768px) {
  .hero-container .hero-contents {
    margin-top: 10rem;
  }
}
.hero-container .hero-contents .hero-header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 33%;
  animation: dropin 750ms ease-out;
  animation-play-state: paused;
}
@media (max-width: 1024px) {
  .hero-container .hero-contents .hero-header {
    text-align: center;
    width: 100%;
  }
}
.hero-container .hero-contents .hero-header .hero-statement {
  font-size: 4.8rem;
  font-weight: 800;
  letter-spacing: 0.25rem;
  color: #003262;
  width: 100%;
}
@media (max-width: 1024px) {
  .hero-container .hero-contents .hero-header .hero-statement {
    text-align: center;
  }
}
.hero-container .hero-contents .hero-header .personal-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2.5rem;
  margin: 1rem 0 5rem 0;
}
@media (max-width: 1024px) {
  .hero-container .hero-contents .hero-header .personal-links {
    justify-content: center;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .hero-container .hero-contents .hero-header .personal-links {
    margin: 1rem 0 2.5rem 0;
  }
}
.hero-container .hero-contents .hero-header .personal-links .personal-link {
  fill: #003262;
  cursor: pointer;
  width: 3.5rem;
  transition: all 100ms ease-in-out;
}
.hero-container .hero-contents .hero-header .personal-links .personal-link:hover {
  fill: #4d7091;
  transition: all 100ms ease-in-out;
}
.hero-container .hero-contents .hero-blurb {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  line-height: 2.5rem;
  color: #003262;
  margin-bottom: 7.5rem;
}
.hero-container .hero-contents .hero-blurb span {
  font-weight: 900;
}
@media (max-width: 768px) {
  .hero-container .hero-contents .hero-blurb {
    margin-bottom: 5rem;
  }
}
@media (max-width: 1024px) {
  .hero-container .hero-contents .reach-out {
    align-self: center;
  }
}
@media (max-width: 768px) {
  .hero-container .hero-contents .reach-out {
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: 0.15rem;
    line-height: 2.5rem;
    color: #003262;
    font-weight: 800;
  }
}
.hero-container .hero-contents .hero-img-wrapper {
  animation: growin 750ms ease-out;
  animation-play-state: paused;
}
.hero-container .hero-contents .hero-img-wrapper .hero-img-container {
  border: 5px solid #003262;
  overflow: hidden;
  height: 45rem;
  width: 45rem;
  animation: border-move 15s ease-in-out infinite;
}
@media (max-width: 475px) {
  .hero-container .hero-contents .hero-img-wrapper .hero-img-container {
    width: 35rem;
    height: 35rem;
  }
}
@media (max-width: 375px) {
  .hero-container .hero-contents .hero-img-wrapper .hero-img-container {
    width: 30rem;
    height: 30rem;
  }
}
.hero-container .hero-contents .hero-img-wrapper .hero-img-container .hero-img {
  width: 100%;
}

@keyframes border-move {
  0% {
    border-radius: 100% 25% 50% 75%;
  }
  25% {
    border-radius: 75% 100% 25% 50%;
  }
  50% {
    border-radius: 50% 75% 100% 25%;
  }
  75% {
    border-radius: 25% 50% 75% 100%;
  }
  100% {
    border-radius: 100% 25% 50% 75%;
  }
}
@keyframes dropin {
  0% {
    transform: translateY(-25%);
    opacity: 0;
  }
  75% {
    transform: translateY(2.5%);
  }
  90% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes growin {
  0% {
    transform: scale(0%);
    opacity: 0;
  }
  75% {
    transform: scale(105%);
  }
  90% {
    transform: scale(97.5%);
  }
  100% {
    transform: scale(100%);
    opacity: 1;
  }
}
.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #003262;
  padding: 5rem;
  width: 100vw;
}
@media (max-width: 768px) {
  .footer-container {
    padding: 1rem;
  }
}
.footer-container .footer-contents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10rem;
}
@media (max-width: 768px) {
  .footer-container .footer-contents {
    flex-direction: column-reverse;
    gap: 1rem;
    padding: 1rem;
  }
}
.footer-container .footer-contents .footer-statement {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  line-height: 2.5rem;
  color: #003262;
  font-weight: 400;
  color: #f7f7f7;
}
@media (max-width: 768px) {
  .footer-container .footer-contents .footer-statement {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    line-height: 3rem;
    color: #003262;
    color: #f7f7f7;
  }
}
.footer-container .footer-contents .footer-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
}
.footer-container .footer-contents .footer-links .footer-link-icon {
  fill: #f7f7f7;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}
.footer-container .footer-contents .footer-links .footer-link-icon:hover {
  fill: #4d7091;
  transition: all 150ms ease-in-out;
}

.about-wrapper {
  background-color: #fbfbfb;
  overflow-x: hidden;
  height: 50vh;
  width: 100vw;
}
@media (max-width: 1280px) {
  .about-wrapper {
    height: -moz-fit-content;
    height: fit-content;
  }
}
@media (max-width: 1024px) {
  .about-wrapper {
    height: -moz-fit-content !important;
    height: fit-content !important;
  }
}
@media (max-height: 800px) {
  .about-wrapper {
    padding: 1rem;
    height: auto;
  }
}
.about-wrapper .about-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10rem;
  height: 100%;
  padding: 0 10rem 0 15rem;
  animation: about-slide-in 750ms ease-out;
  animation-play-state: paused;
}
@media (max-width: 1600px) {
  .about-wrapper .about-container {
    gap: 5rem;
    width: 80%;
    padding: 2.5rem;
  }
}
@media (max-width: 1024px) {
  .about-wrapper .about-container {
    flex-direction: column;
    gap: 2.5rem;
    width: 90%;
  }
}
@media (max-width: 768px) {
  .about-wrapper .about-container {
    flex-direction: column;
    gap: 2.5rem;
    width: 100%;
  }
}
.about-wrapper .about-container .about-img-container {
  border: 4px solid #003262;
  overflow: hidden;
  width: 40%;
  animation: about-border-move 15s ease-in-out infinite;
}
@media (max-width: 1600px) {
  .about-wrapper .about-container .about-img-container {
    width: 50%;
  }
}
@media (max-width: 1280px) {
  .about-wrapper .about-container .about-img-container {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .about-wrapper .about-container .about-img-container {
    width: 40%;
  }
}
@media (max-width: 768px) {
  .about-wrapper .about-container .about-img-container {
    width: 50%;
  }
}
.about-wrapper .about-container .about-img {
  display: block;
  width: 100%;
}
@media (max-width: 1024px) {
  .about-wrapper .about-container .about-info {
    width: 100%;
  }
}
.about-wrapper .about-container .about-info .about-text {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2.5rem;
}
@media (max-width: 1024px) {
  .about-wrapper .about-container .about-info .about-text {
    width: 100%;
  }
}
.about-wrapper .about-container .about-info .about-text .about-title {
  font-size: 3.2rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
  color: #003262;
  text-transform: uppercase;
}
.about-wrapper .about-container .about-info .about-text .about-blurb {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  line-height: 2.5rem;
  color: #003262;
  font-weight: 400;
}
@media (max-width: 475px) {
  .about-wrapper .about-container .about-info .about-text .about-blurb {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    line-height: 3rem;
    color: #003262;
    font-weight: 500;
    letter-spacing: 0.175rem;
    line-height: 2.5rem;
  }
}
@media (max-width: 1024px) {
  .about-wrapper .about-container .about-info .about-text {
    align-items: center;
    justify-content: center;
    gap: 2.5rem;
    text-align: center;
    width: 100%;
  }
}
.about-wrapper .about-container .about-info .tech-stack {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
  margin: 5rem 0 0 0;
}
@media (max-width: 1024px) {
  .about-wrapper .about-container .about-info .tech-stack {
    flex-direction: column;
    gap: 2.5rem;
    margin: 2.5rem 0 2.5rem 0;
  }
}
.about-wrapper .about-container .about-info .tech-stack .tech-stack-title {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  line-height: 2.5rem;
  color: #003262;
  border-right: 1px solid #003262;
  padding-right: 1.5rem;
}
@media (max-width: 1024px) {
  .about-wrapper .about-container .about-info .tech-stack .tech-stack-title {
    align-self: center;
    border-right: none;
    border-bottom: 1px solid #003262;
    padding-right: 0;
  }
}
.about-wrapper .about-container .about-info .tech-stack .tech-stack-inner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  flex-wrap: wrap;
}
@media (max-width: 1024px) {
  .about-wrapper .about-container .about-info .tech-stack .tech-stack-inner-container {
    flex-wrap: wrap;
  }
}

@keyframes about-border-move {
  0% {
    border-radius: 70% 60% 70% 50%/60% 50% 70% 80%;
  }
  25% {
    border-radius: 60% 50% 70% 80%/50% 70% 60% 70%;
  }
  50% {
    border-radius: 50% 80% 60% 70%/50% 70% 60% 70%;
  }
  75% {
    border-radius: 60% 80% 50% 70%/60% 80% 50% 70%;
  }
  100% {
    border-radius: 70% 60% 70% 50%/60% 50% 70% 80%;
  }
}
@keyframes about-slide-in {
  0% {
    transform: translateX(5%);
    opacity: 0;
  }
  75% {
    transform: translateX(-2%);
  }
  90% {
    transform: translateX(1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}
.projects-wrapper {
  background-color: #f7f7f7;
  overflow-x: hidden;
  width: 100vw;
}
.projects-wrapper .projects-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5rem;
  padding: 10rem 5rem;
  height: 100%;
  width: 100%;
}
@media (max-width: 768px) {
  .projects-wrapper .projects-container {
    gap: 2.5rem;
    padding: 2.5rem;
  }
}
.projects-wrapper .projects-container .projects-title {
  font-size: 3.2rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
  color: #003262;
  text-transform: uppercase;
}
.projects-wrapper .projects-container .tech-stack {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 10%;
}
.projects-wrapper .projects-container .tech-stack .tech-stack-title {
  font-size: 3.2rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
  color: #003262;
  color: #003262;
}
.projects-wrapper .projects-container .tech-stack .tech-stack-inner-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3.5rem;
  width: auto;
  padding: 1.5rem 3rem;
}
.projects-wrapper .projects-container .tech-stack .tech-stack-inner-container .stack-item {
  padding: 1rem;
}
.projects-wrapper .projects-container .tech-stack .tech-stack-inner-container .stack-item .svg-logo {
  cursor: pointer;
  width: 5rem;
}
.projects-wrapper .projects-container .projects-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 7.5rem;
  height: 100%;
  padding: 2.5rem;
}
@media (max-width: 375px) {
  .projects-wrapper .projects-container .projects-section {
    padding: 1rem;
    gap: 5rem;
  }
}

.contact-wrapper {
  background-color: #fbfbfb;
  width: 100vw;
}
.contact-wrapper .contact-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  animation: contact-slide-up 750ms ease-out;
  animation-play-state: paused;
}
.contact-wrapper .contact-container .contact-contents {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2.5rem;
  height: 100%;
  width: 70%;
  margin: 2.5rem 1.25rem;
  padding: 2.5rem;
}
@media (max-width: 768px) {
  .contact-wrapper .contact-container .contact-contents {
    width: 100%;
  }
}
@media (max-width: 475px) {
  .contact-wrapper .contact-container .contact-contents {
    padding: 0;
  }
}
.contact-wrapper .contact-container .contact-contents .form-title {
  font-size: 3.2rem;
  font-weight: 800;
  letter-spacing: 0.2rem;
  color: #003262;
  color: #002345;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .contact-wrapper .contact-container .contact-contents .form-title {
    font-size: 2.4rem;
    font-weight: 800;
    letter-spacing: 0.15rem;
    color: #003262;
  }
}
.contact-wrapper .contact-container .contact-contents .contact-blurb {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.15rem;
  line-height: 2.5rem;
  color: #003262;
  text-align: center;
  width: 65%;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .contact-wrapper .contact-container .contact-contents .contact-blurb {
    width: 100%;
  }
}
@media (max-width: 475px) {
  .contact-wrapper .contact-container .contact-contents .contact-blurb {
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    line-height: 3rem;
    color: #003262;
  }
}

@keyframes contact-slide-up {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  75% {
    transform: translateY(-2.5%);
  }
  90% {
    transform: translateY(1%);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
.project-wrapper .project {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.5rem;
  outline: 1px solid rgba(77, 112, 145, 0.2);
  outline-offset: 1rem;
  height: 100%;
  width: 80%;
  margin: 0 auto;
  transition: all 150ms ease-in-out;
  animation: proj-slide-left 750ms ease-out;
  animation-play-state: paused;
}
@media (max-width: 375px) {
  .project-wrapper .project {
    width: 70%;
  }
}
.project-wrapper .project:hover {
  outline: 1px solid #4d7091;
  transition: all 150ms ease-in-out;
}
.project-wrapper .project.reverse {
  flex-direction: row-reverse;
  animation: proj-slide-right 750ms ease-out;
  animation-play-state: paused;
}
@media (max-width: 1024px) {
  .project-wrapper .project.reverse {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1280px) {
  .project-wrapper .project {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .project-wrapper .project {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}
.project-wrapper .project .project-img-container {
  overflow: hidden;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.2);
  width: 100%;
}
.project-wrapper .project .project-img-container .project-img {
  display: block;
  width: 100%;
}
.project-wrapper .project .project-info {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 100%;
  padding: 2.5rem;
}
@media (max-width: 1024px) {
  .project-wrapper .project .project-info {
    padding: 1rem;
  }
}
.project-wrapper .project .project-info .project-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2.5rem 0;
}
@media (max-width: 768px) {
  .project-wrapper .project .project-info .project-heading {
    padding: 1rem 0;
  }
}
.project-wrapper .project .project-info .project-heading .project-title {
  font-size: 2.4rem;
  font-weight: 800;
  letter-spacing: 0.15rem;
  color: #003262;
  text-transform: uppercase;
}
.project-wrapper .project .project-info .project-heading .project-date {
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  line-height: 3rem;
  color: #003262;
  letter-spacing: 0.05rem;
}
.project-wrapper .project .project-info .project-about {
  flex-grow: 1;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.1rem;
  line-height: 3rem;
  color: #003262;
  line-height: 2rem;
}
.project-wrapper .project .project-info .project-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
  width: 100%;
  padding-top: 3.5rem;
}
@media (max-width: 768px) {
  .project-wrapper .project .project-info .project-details {
    gap: 2.5rem;
  }
}
.project-wrapper .project .project-info .project-details .project-skills {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}
@media (max-width: 475px) {
  .project-wrapper .project .project-info .project-details .project-skills {
    padding: 1rem 0;
  }
}
.project-wrapper .project .project-info .project-details .project-skills .project-skill {
  background-color: #b3c2d0;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  line-height: 3rem;
  color: #003262;
  line-height: 2rem;
  padding: 0.5rem 1rem;
}
@media (max-width: 475px) {
  .project-wrapper .project .project-info .project-details .project-skills .project-skill {
    padding: 0.25rem 0.5rem;
  }
}
.project-wrapper .project .project-info .project-details .project-links {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: 1.5rem;
}
.project-wrapper .project .project-info .project-details .project-links .project-link {
  fill: #003262;
  width: 2.5rem;
  height: 2.5rem;
}
.project-wrapper .project .project-info .project-details .project-links .external {
  stroke: #003262;
  fill: transparent;
}

.disabled {
  pointer-events: none;
  filter: contrast(20%);
}

@keyframes proj-slide-left {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  75% {
    transform: translateX(-2.5%);
  }
  90% {
    transform: translateX(1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes proj-slide-right {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  75% {
    transform: translateX(2.5%);
  }
  90% {
    transform: translateX(-1%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}/*# sourceMappingURL=styles.css.map */