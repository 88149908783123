@use "./typography" as *;
@use "./colors" as *;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-family: $font;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

.container {
  width: 1600px;
  max-width: 1600px;
  margin: 0 auto;
  padding: 2rem;

  @media (max-width: 1600px) {
    max-width: 1280px;
  }

  @media (max-width: 1280px) {
    max-width: 1024px;
  }

  @media (max-width: 1024px) {
    max-width: 768px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.btn {
  @include h4-text;
  box-shadow: -3.5px 3.5px 0px 3.5px $light-blue,
    3.5px -3.5px 0px 3.5px $dark-blue;
  background-color: $off-white;
  color: $blue;

  padding: 0.75rem;

  animation: compound-shadow-reverse 350ms;

  &:hover {
    animation: compound-shadow 350ms forwards;
  }
}

@keyframes compound-shadow {
  from {
    box-shadow: -3.5px 3.5px 0px 3.5px $light-blue,
      3.5px -3.5px 0px 3.5px $dark-blue;
    background-color: $off-white;
    color: $blue;
  }
  to {
    box-shadow: 0px 0px 0px 0px $light-blue, 0px 0px 0px 0px $dark-blue;
    background-color: $blue;
    color: $white;
  }
}

@keyframes compound-shadow-reverse {
  from {
    box-shadow: 0px 0px 0px 0px $light-blue, 0px 0px 0px 0px $dark-blue;
    background-color: $blue;
    color: $white;
  }
  to {
    box-shadow: -3.5px 3.5px 0px 3.5px $light-blue,
      3.5px -3.5px 0px 3.5px $dark-blue;
    background-color: $off-white;
    color: $blue;
  }
}
